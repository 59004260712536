import { MobileFooter } from 'components/FooterLP';
import { routes } from 'constants/routes';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import useLanguage from 'hooks/useLanguage';
import React from 'react';

export default function Footer({ withFlags, ...props }) {
	const { language } = useLanguage();
	
	return (
		<footer tw="pt-10 xl:pt-05 border-t border-solid border-grey-E" {...props}>
			<div className="">
				<MobileFooter />
			</div>
			{withFlags && (
				<div className='py-30 xl:py-15 border-t border-solid border-grey-E'>
					<div className='w-75/100 sm:min-w-300 sm:w-30/100 m-auto cursor-pointer'>
						<Link to={routes[language].UE}> 
							<StaticImage src='../assets/footer/ue-flag-optimized.png' alt="" placeholder='tracedSVG' />
						</Link>
					</div>
				</div>
			)}
		</footer>
	);
}
