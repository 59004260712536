import MaskButtonLarge from 'assets/main/mask-btn-large.inline.svg';
import MaskButtonNormal from 'assets/main/mask-btn-normal.inline.svg';
import React from 'react';
import tw, { css } from 'twin.macro';

const cssBase = css`
  ${tw`absolute bottom-0 z-10`}
`;

export default function FloatButton({ mode, center, children, onClick, ...props }) {
	if (mode === 'large') {
		return (
			<div css={[cssBase, tw`sm:-mb-20 xl:-mb-15`]} {...props}>
				<div style={{ transform: 'translate(-35px,8px)' }} className="absolute bottom-0"><MaskButtonLarge/></div>
				<div className="absolute bottom-0" onClick={onClick}>{children}</div>
			</div>
		);
	}
	if (mode === 'mobile') {
		return (
			<div style={{ marginLeft: '-3.5rem' }} css={[cssBase, tw`-mb-20 xs:-mb-15`]} {...props}>
				<div style={{ transform: 'translate(-35px,8px)' }} className="absolute bottom-0"><MaskButtonLarge/></div>
				<div className="absolute bottom-0" onClick={onClick}>{children}</div>
			</div>
		);
	}

	return (
		<div css={[cssBase, !center && tw`right-0 mr-70`, tw`-mb-15`]} {...props}>
			<div style={{ transform: 'translate(-27px,8px)' }} className="absolute bottom-0"><MaskButtonNormal/></div>
			<div className="absolute bottom-0" onClick={onClick}>{children}</div>
		</div>
	);
}

FloatButton.defaultProps = {
	mode: 'normal',
	onClick: () => {}
};
